import React from "react"
import cn from "classnames"

export default function Paragraph(props) {
  return (
    <p
      className={cn(
        "leading-6 text-gray-900",
        props.size === "xs" && "text-xs",
        props.size === "sm" && "text-sm",
        props.size === "md" && "text-base",
        props.align === "center" && "text-center",
      )}
    >
      {props.children}
    </p>
  )
}
