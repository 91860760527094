import React from "react"
import { useRouter } from "next/router"
import { QueryClientProvider, QueryClient } from "react-query"
import Rollbar from "rollbar"
import { ToastContainer, Flip, toast } from "react-toastify"
import qs from "qs"
import jwtDecode from "jwt-decode"
import { basicLogger, withLDProvider } from "launchdarkly-react-client-sdk"
import { webAuth } from "singletons/auth0"
import WhitelabelProvider from "providers/WhitelabelProvider"
import Layout from "components/Layout"
import Flex from "components/Flex"
import Container from "components/Container"
import Logo from "components/Logo"
import Card from "components/Card"
import Heading from "components/Heading"
import Paragraph from "components/Paragraph"
import Button from "components/Button"
import Link from "components/Link"
import Spacer from "components/Spacer"
import trackPage from "utils/trackPage"
import initNewPageViewedEvent from "../../../src/utils/initNewPageViewedEvent"
import "styles/index.css"
import cookieStorage from "../../../src/utils/cookieStorage"
import { MdErrorOutline } from "react-icons/md"
import sendRequest from "utils/sendRequest"
import { getEnvironment } from "@dnsfilter/shared"

if (typeof document !== "undefined") {
  if (
    process.env.NEXT_PUBLIC_ROLLBAR_TOKEN &&
    (process.env.REACT_APP_VERCEL_ENV === "production" ||
      process.env.REACT_APP_VERCEL_ENV === "preview")
  ) {
    window.Rollbar = new Rollbar({
      accessToken: process.env.NEXT_PUBLIC_ROLLBAR_TOKEN,
      payload: {
        client: {
          javascript: {
            code_version: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
            source_map_enabled: true,
            guess_uncaught_frames: true,
          },
        },
        server: {
          branch: process.env.REACT_APP_VERCEL_GIT_COMMIT_REF,
          host: process.env.REACT_APP_VERCEL_URL,
          root: "webpack://_N_E/./src/",
        },
        environment: getEnvironment(window.location.href),
        context: "_next",
        payload: {
          locationOrigin: window.location.origin,
        },
      },
      transform: (payload) => {
        payload.body.trace.frames.forEach((frame) => {
          if (frame.filename) {
            frame.filename = frame.filename.replace(
              payload.payload.locationOrigin,
              `${window.location.protocol}//${payload.server.host}`,
            )
          }
        })
      },
      captureUncaught: true,
      captureUnhandledRejections: true,
    })
  }

  window.trackAnalyticsEvent = (event, properties = {}) => {
    window.analytics?.track(event, properties)

    sendRequest({
      method: "post",
      path: "/segment",
      payload: {
        event,
        properties: {
          ...properties,
          organization_id:
            properties.organization_id || localStorage.getItem("orgId"),
        },
      },
    }).catch(() => {
      // Do nothing
    })
  }
}

const queryClient = new QueryClient()

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    window.Rollbar?.error(error, errorInfo.componentStack)
  }

  render() {
    if (this.state.error) {
      return (
        <div className="flex h-screen items-center justify-center">
          <article className="prose text-center">
            <h2>Something went wrong.</h2>
            <button
              type="button"
              onClick={() => this.setState({ error: null })}
            >
              Try again
            </button>
          </article>
        </div>
      )
    }

    return this.props.children
  }
}

function App(props) {
  const router = useRouter()

  React.useEffect(() => {
    try {
      initNewPageViewedEvent({
        getUserId: function () {
          return localStorage.getItem("userId")
        },
        getUserEmail: function () {
          try {
            return jwtDecode(localStorage.getItem("access_token"))[
              "https://dnsfilter.com/email"
            ]
          } catch {
            return null
          }
        },
        getOrganizationId: function () {
          return localStorage.getItem("orgId")
        },
        getOrganizationName: function () {
          return localStorage.getItem("orgName")
        },
        shouldRegisterAppParams: true,
      })
    } catch (error) {
      console.error("Failed to dispatch page viewed event", error)
    }

    trackPage(router.pathname)
  }, [router.pathname])

  React.useEffect(() => {
    const urlParams = qs.parse(window.location.search.substr(1))

    const utmParams = [
      "dtm_campaign",
      "dtm_content",
      "dtm_medium",
      "dtm_source",
      "dtm_term",
      "utm_campaign",
      "utm_content",
      "utm_medium",
      "utm_source",
      "utm_term",
    ]

    if (
      utmParams.map((param) => urlParams[param]).filter((value) => value).length
    ) {
      const parsedUtmParams = utmParams.reduce(
        (acc, param) =>
          urlParams[param]
            ? {
                ...acc,
                [param.replace("utm_", "").replace("dtm_", "")]:
                  urlParams[param],
              }
            : acc,
        {},
      )

      cookieStorage.setItem(
        "dnsfilterUTMParams",
        String(qs.stringify(parsedUtmParams).substr(0, 255)),
      )
    }

    if (urlParams.distributor) {
      cookieStorage.setItem("dnsfilterDistributor", urlParams.distributor)
    }

    if (urlParams.plan) {
      cookieStorage.setItem("plan", urlParams.plan)
    }

    if (urlParams.ub) {
      cookieStorage.setItem("ub", urlParams.ub)
    }
  }, [])

  React.useEffect(() => {
    const urlParams = qs.parse(window.location.search.substr(1))

    const signupParams = [
      "first_name",
      "last_name",
      "email",
      "phone",
      "industry",
      "org_name",
      "use_case",
    ]

    if (
      signupParams.map((param) => urlParams[param]).filter((value) => value)
        .length
    ) {
      const parsedSignupParams = signupParams.reduce(
        (acc, param) =>
          urlParams[param]
            ? {
                ...acc,
                [param]: urlParams[param],
              }
            : acc,
        {},
      )

      cookieStorage.setItem(
        "dnsfilterSignupParams",
        qs.stringify(parsedSignupParams),
      )
    }
  }, [])

  const [parsingHash, setParsingHash] = React.useState(() =>
    typeof window !== "undefined"
      ? !!window.location.hash.match(/access_token|id_token/)
      : false,
  )

  React.useEffect(() => {
    // We'll need to check for both `access_token` and `id_token` because for SAML connections `access_token` is not a JWT token. So in Auth0 connection config we set SAML connection `responseType` to `token id_token` and we'll get both tokens in the response. You might ask why we are not passing `responseType` to `authorize()` programatically in that case? Well, Auth0 seems to ignore the programatic `responseType` for SAML connections, so we need to set it manually within Auth0 > Connections > SAML > IdP-Initiated SSO > Query String field, i.e. `redirect_uri=http://localhost:3000/login?connection=dnsfilteroktasaml&scope=openid email&response_type=token id_token`

    if (!window.location.hash.match(/access_token|id_token/)) {
      return
    }

    setParsingHash(true)

    let parseHashOptions = {
      hash: window.location.hash,
    }

    // For IdP-initiated flows we need to pass `__enableIdPInitiatedLogin` to the `parseHash` function.
    // More details: https://auth0.com/docs/configure/saml-configuration/saml-sso-integrations/identity-provider-initiated-single-sign-on#lock-auth0-js

    // We also need to append `?connection` to the callback URL, so we take that opportunity to set `__enableIdPInitiatedLogin` only when this `connection` param is present
    // More details: https://auth0.com/docs/configure/saml-configuration/saml-sso-integrations/identity-provider-initiated-single-sign-on#post-back-url

    // Finally, we are manually removing `state` from the URL hash because setting `__enableIdPInitiatedLogin` is not enough to bypass the `state` check. It's probably a bug in `auth0-js`.
    // See here: https://github.com/auth0/auth0.js/blob/46f4dbbfd4aba52eda0440223a36634acc342911/src/web-auth/index.js#L309-L318
    // `var shouldBypassStateChecking = !state && !transactionState && options.__enableIdPInitiatedLogin;`
    // `shouldBypassStateChecking` will be false if `state` is present, not sure why their documentation didn't say anything about that.

    if (window.location.search.includes("?connection=")) {
      const hashParams = new URLSearchParams(window.location.hash.slice(1))

      hashParams.delete("state")

      parseHashOptions = {
        hash: `#${hashParams.toString()}`,
        __enableIdPInitiatedLogin: true,
      }
    }

    webAuth.parseHash(parseHashOptions, (error, result) => {
      // Let's remove and search and hash params from the URL
      window.history.replaceState(
        null,
        document.title,
        window.location.origin + window.location.pathname,
      )

      if (error) {
        toast.error(error.errorDescription)
        setParsingHash(false)
        return
      }

      // Even if the hash was parsed succesfully an empty result might be returned
      if (!result) {
        setParsingHash(false)
        return
      }

      // See explanation above, right after `React.useEffect(() => {`
      const accessToken = result.idToken ?? result.accessToken

      // TODO: extract function and apply to `services/login.js` as well
      localStorage.setItem("didLogin", "true")
      localStorage.setItem("access_token", accessToken)

      localStorage.removeItem("subscriptionIssuesAlertDismissed")
      localStorage.removeItem("legacyEnterpriseAlertDismissed")
      localStorage.removeItem("billingDetailsAlertDismissed")
      localStorage.removeItem("billingDetailsShownAndClosed")

      if (
        router.pathname === "/login" &&
        sessionStorage.getItem("previous_access_token")
      ) {
        const googleToken = localStorage.getItem("access_token")
        const usernamePasswordToken = sessionStorage.getItem(
          "previous_access_token",
        )

        localStorage.setItem("access_token", usernamePasswordToken)
        sendRequest({
          url: "/auth_identities",
          method: "post",
          payload: {
            additional_token: googleToken,
          },
        })
          .then(() => {
            sessionStorage.removeItem("previous_access_token")
            window.location.href = "/account/settings"
          })
          .catch((e) => {
            sessionStorage.removeItem("previous_access_token")
            window.location.href = "/account/settings?error=mismatch"
          })
      } else {
        window.location.href = "/?last=login"
      }
    })
  }, [router])

  if (
    typeof window !== "undefined" &&
    (window.navigator.userAgent.indexOf("MSIE") > -1 ||
      navigator.userAgent.match(/Trident\/7\./))
  ) {
    return (
      <Layout title="Dashboard">
        <Flex value="flex-1 flex flex-col justify-center items-center">
          <Container size="sm">
            <Card>
              <Paragraph size="sm">
                At this time our Dashboard doesn't support Internet Explorer.
              </Paragraph>
              <Spacer size="h-6" />
              <Paragraph size="sm">
                Please use an alternate browser, such as{" "}
                <Link
                  href="https://www.google.com/chrome/browser/desktop/index.html"
                  raw
                >
                  Chrome
                </Link>
                ,{" "}
                <Link href="https://www.mozilla.org/en-US/firefox/new/" raw>
                  Firefox
                </Link>
                , Edge or Safari.
              </Paragraph>
            </Card>
          </Container>
        </Flex>
      </Layout>
    )
  }

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <WhitelabelProvider>
          {parsingHash ? (
            <Layout title="Sign In">
              <Flex value="flex-1 flex flex-col justify-center items-center">
                <h1>
                  <Logo />
                </h1>
                <Spacer size="h-8" />
                <Container size="xs">
                  <Card>
                    <Heading align="center">Signing you in</Heading>
                    <Spacer size="h-8" />
                    <Button variant="primary" size="md" loading>
                      Loading
                    </Button>
                  </Card>
                </Container>
              </Flex>
            </Layout>
          ) : (
            <SSOErrorHandler>
              <props.Component {...props.pageProps} />
            </SSOErrorHandler>
          )}
        </WhitelabelProvider>
        <ToastContainer
          toastClassName="pl-5"
          closeButton={null}
          position={toast.POSITION.TOP_RIGHT}
          transition={Flip}
        />
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

function SSOErrorHandler(props) {
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    const error = new URLSearchParams(window.location.hash).get(
      "error_description",
    )

    if (!error) {
      return
    }

    setError(error)

    // Let's remove and search and hash params from the URL
    window.history.replaceState(
      null,
      document.title,
      window.location.origin + window.location.pathname,
    )
  }, [])

  if (error) {
    return (
      <Layout title="Sign In">
        <Flex value="flex-1 flex flex-col justify-center items-center">
          <h1>
            <Logo />
          </h1>
          <Spacer size="h-8" />
          <Container size="xs">
            <Card>
              <div className="flex justify-center">
                <div className="rounded-full bg-red-100 p-3">
                  <MdErrorOutline className="h-5 w-5 text-red-500" />
                </div>
              </div>
              <Spacer size="h-4" />
              <Heading align="center">Login Failed</Heading>
              <Spacer size="h-4" />
              <Paragraph align="center">
                {error?.includes("invalid_client")
                  ? "Your login attempt failed due to an invalid client secret in your organizations single sign-on configuration."
                  : error}
              </Paragraph>
              <Spacer size="h-5" />
              <Button
                variant="primary"
                size="md"
                onClick={() => setError(null)}
              >
                Dismiss
              </Button>
            </Card>
          </Container>
        </Flex>
      </Layout>
    )
  }

  return props.children
}

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
  options: {
    logger: basicLogger({ level: "warn" }),
  },
})(App)
