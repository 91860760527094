import React from "react"

export const GoogleIcon = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_284_1271)">
      <path
        d="M9.99998 4.58431C11.475 4.58431 12.7958 5.09264 13.8375 6.08431L16.6917 3.23014C14.9583 1.61764 12.6958 0.625977 9.99998 0.625977C6.09165 0.625977 2.71248 2.86764 1.06665 6.13431L4.39165 8.71348C5.17915 6.34264 7.39165 4.58431 9.99998 4.58431Z"
        fill="#EA4335"
      />
      <path
        d="M19.575 10.8548C19.575 10.2007 19.5125 9.56732 19.4167 8.95898H10V12.7173H15.3917C15.15 13.9507 14.45 15.0007 13.4 15.709L16.6208 18.209C18.5 16.4673 19.575 13.8923 19.575 10.8548Z"
        fill="#4285F4"
      />
      <path
        d="M4.3875 12.5389C4.1875 11.9348 4.07083 11.2931 4.07083 10.6264C4.07083 9.95977 4.18333 9.3181 4.3875 8.71393L1.0625 6.13477C0.383333 7.48477 0 9.00977 0 10.6264C0 12.2431 0.383333 13.7681 1.06667 15.1181L4.3875 12.5389Z"
        fill="#FBBC05"
      />
      <path
        d="M10 20.6258C12.7 20.6258 14.9708 19.7383 16.6208 18.205L13.4 15.705C12.5042 16.3092 11.35 16.6633 10 16.6633C7.39167 16.6633 5.17917 14.905 4.3875 12.5342L1.0625 15.1133C2.7125 18.3842 6.09167 20.6258 10 20.6258Z"
        fill="#34A853"
      />
    </g>
    <defs>
      <clipPath id="clip0_284_1271">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.625977)"
        />
      </clipPath>
    </defs>
  </svg>
)
