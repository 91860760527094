import React from "react"
import cn from "classnames"

export default function Card(props) {
  return (
    <div className={cn("rounded-lg bg-white p-6 shadow-xl", props.className)}>
      {props.children}
    </div>
  )
}
