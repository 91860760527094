import React from "react"
import qs from "qs"
import WhitelabelContext from "contexts/WhitelabelContext"
import Layout from "components/Layout"
import Flex from "components/Flex"
import Container from "components/Container"
import Card from "components/Card"
import Paragraph from "components/Paragraph"
import useResource from "hooks/useResource"
import sendRequest from "utils/sendRequest"
import isBrandedDomain from "../../../src/utils/isBrandedDomain"

const dnsfilterConfig = {
  data: {
    favicon: {
      src: `${process.env.NEXT_PUBLIC_ASSET_PREFIX}/favicon.png`,
    },
    logo: {
      src: `${process.env.NEXT_PUBLIC_ASSET_PREFIX}/logo.png`,
      alt: "DNSFilter",
    },
    background: {
      src: "https://download.dnsfilter.com/static/media/map_bg.svg",
    },
    name: "DNSFilter",
    description:
      "AI Powered Content Filtering & Threat Protection - DNSFilter®",
    serviceProvidedBy: "dnsfilter",
  },
  meta: {},
}

export default function WhitelabelProvider(props) {
  const whitelabel = useResource({
    initialParams: {},
    fetch: () => {
      if (isBrandedDomain(window.location.href)) {
        return Promise.resolve(dnsfilterConfig)
      }

      const domain =
        qs.parse(window.location.search.substr(1)).domain ??
        (window.location.host.match(
          /localhost|\.dnsfilter\.(com|app|cloud)|\.vix.docker/,
        )
          ? null
          : window.location.host)

      return domain
        ? sendRequest({
            method: "get",
            path: "/site",
            payload: {
              domain,
            },
          }).then((response) => ({
            data: {
              favicon: {
                src: response.data.custom_favicon,
              },
              logo: {
                src: response.data.custom_logo,
                alt: response.data.name,
              },
              background: {
                src: null,
              },
              name: response.data.name,
              description: null,
              serviceProvidedBy: response.data.name,
            },
            meta: {},
          }))
        : sendRequest({
            method: "get",
            path: "/site/distributor",
            payload: {
              domain: window.location.host,
            },
          })
            .then((response) => ({
              data: {
                favicon: {
                  src: `${process.env.NEXT_PUBLIC_ASSET_PREFIX}/favicon.png`,
                },
                logo: {
                  src: response.data.logo_url,
                  alt: response.data.name,
                },
                background: {
                  src: "https://download.dnsfilter.com/static/media/map_bg.svg",
                },
                name: response.data.name,
                description: null,
                serviceProvidedBy: "dnsfilter",
                distributor_id: response.data.stated_distributor,
              },
              meta: {},
            }))
            .catch(() => dnsfilterConfig)
    },
  })

  if (whitelabel.failure) {
    return (
      <Layout title="Dashboard">
        <Flex value="flex-1 flex flex-col justify-center items-center">
          <Container size="xs">
            <Card>
              <Paragraph size="sm" align="center">
                <strong>This site is not currently configured.</strong>
                <br />
                Contact your system administrator.
              </Paragraph>
            </Card>
          </Container>
        </Flex>
      </Layout>
    )
  }

  return (
    <WhitelabelContext.Provider
      value={
        whitelabel.data ?? {
          favicon: {
            src: null,
          },
          logo: {
            src: null,
            alt: "Dashboard",
          },
          background: {
            src: null,
          },
          name: "Dashboard",
          description: null,
          serviceProvidedBy: null,
        }
      }
    >
      {props.children}
    </WhitelabelContext.Provider>
  )
}
