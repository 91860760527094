import React from "react"
import useWhitelabel from "hooks/useWhitelabel"

export default function Logo() {
  const whitelabel = useWhitelabel()

  return (
    <img
      className={whitelabel.distributor_id ? "h-auto max-w-sm" : "h-auto w-60"}
      src={whitelabel.logo.src}
      alt={whitelabel.logo.alt}
    />
  )
}
