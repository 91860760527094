import auth0 from "auth0-js"

const config = {
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  audience: `https://${process.env.NEXT_PUBLIC_AUTH0_SUBDOMAIN}.auth0.com/mfa/`,
  responseType: "token",
  redirectUri:
    typeof window !== "undefined"
      ? `${window.location.origin}/login`
      : undefined,
}

export const webAuth = new auth0.WebAuth(config)

export const authentication = new auth0.Authentication(config)
