import React from "react"
import Head from "next/head"
import useWhitelabel from "hooks/useWhitelabel"

export default function Layout(props) {
  const whitelabel = useWhitelabel()

  return (
    <>
      <Head>
        {(props.description || whitelabel.description) && (
          <meta
            name="description"
            content={props.description || whitelabel.description}
          />
        )}
        <title>
          {props.title} | {whitelabel.name}
        </title>
        {whitelabel.favicon.src && (
          <link rel="icon" href={whitelabel.favicon.src} />
        )}
      </Head>
      <div
        className="flex min-h-screen flex-col bg-center bg-no-repeat p-8"
        style={
          whitelabel.background.src
            ? {
                backgroundImage: `url(${whitelabel.background.src})`,
              }
            : undefined
        }
      >
        {props.children}
      </div>
    </>
  )
}
