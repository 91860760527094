import React from "react"
import cn from "classnames"

export default function Container(props) {
  return (
    <div
      className={cn(
        "mx-auto w-full",
        props.size === "xs" && "max-w-sm",
        props.size === "sm" && "max-w-lg",
        props.size === "md" && "max-w-4xl",
      )}
    >
      {props.children}
    </div>
  )
}
