import React from "react"
import BaseLink from "next/link"

export default function Link(props) {
  let className =
    "font-medium text-brand-primary transition ease-in-out duration-150 focus:outline-none focus:underline"

  if (props.raw) {
    return (
      <a
        data-test-id={props.testId ? props.testId : "link"}
        className={props.unstyled ? undefined : className}
        href={props.href}
        target={props.target}
        style={props.style}
      >
        {props.children}
      </a>
    )
  }

  if (props.href) {
    return (
      <BaseLink href={props.href} as={props.as ?? props.href}>
        {/* eslint-disable-next-line */}
        <a
          data-test-id={props.testId ? props.testId : "link"}
          className={props.unstyled ? undefined : className}
          style={props.style}
        >
          {props.children}
        </a>
      </BaseLink>
    )
  }

  return (
    <button className={className} style={props.style} onClick={props.onClick}>
      {props.children}
    </button>
  )
}
