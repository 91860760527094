export default function trackPage(url) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "Pageview",
      url,
    })
  }

  if (window.analytics) {
    window.analytics.page(url)
  }
}
