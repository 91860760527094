import React from "react"
import cn from "classnames"
import { GoogleIcon } from "./GoogleIcon"

const Button = React.forwardRef((props, ref) => (
  <button
    ref={ref}
    className={cn(
      "flex h-10 w-full items-center justify-center rounded-lg px-4 text-base font-bold uppercase tracking-widest focus:outline-none",
      props.loading || props.disabled
        ? ["cursor-auto", props.disabled && "opacity-75"]
        : "transition duration-150 ease-in-out",
      (!props.variant || props.variant === "primary") && [
        "bg-brand-primary text-white",
        props.loading || props.disabled ? null : "focus:shadow-outline-blue",
      ],
      (!props.variant || props.variant === "secondary") && [
        "border border-gray-300 bg-white text-gray-900 shadow-sm",
        props.loading || props.disabled ? null : "focus:shadow-outline-blue",
      ],
      props.variant === "danger" && [
        "bg-red-600 text-white",
        props.loading || props.disabled
          ? null
          : "focus:shadow-outline-red hover:bg-red-500 active:bg-red-700",
      ],
    )}
    type={props.loading || props.disabled ? "button" : props.type ?? "button"}
    disabled={props.loading || props.disabled}
    onClick={props.onClick}
    data-test-id={props.testId ?? "button"}
  >
    {props.loading ? (
      <img
        className="w-8"
        src={`data:image/svg+xml,%3Csvg width='120' height='30' xmlns='http://www.w3.org/2000/svg' fill='%23${
          props.variant === "secondary" ? "333" : "fff"
        }'%3E%3Ccircle cx='15' cy='15' r='15'%3E%3Canimate attributeName='r' from='15' to='15' begin='0s' dur='0.8s' values='15;9;15' calcMode='linear' repeatCount='indefinite'/%3E%3Canimate attributeName='fill-opacity' from='1' to='1' begin='0s' dur='0.8s' values='1;.5;1' calcMode='linear' repeatCount='indefinite'/%3E%3C/circle%3E%3Ccircle cx='60' cy='15' r='9' fill-opacity='.3'%3E%3Canimate attributeName='r' from='9' to='9' begin='0s' dur='0.8s' values='9;15;9' calcMode='linear' repeatCount='indefinite'/%3E%3Canimate attributeName='fill-opacity' from='.5' to='.5' begin='0s' dur='0.8s' values='.5;1;.5' calcMode='linear' repeatCount='indefinite'/%3E%3C/circle%3E%3Ccircle cx='105' cy='15' r='15'%3E%3Canimate attributeName='r' from='15' to='15' begin='0s' dur='0.8s' values='15;9;15' calcMode='linear' repeatCount='indefinite'/%3E%3Canimate attributeName='fill-opacity' from='1' to='1' begin='0s' dur='0.8s' values='1;.5;1' calcMode='linear' repeatCount='indefinite'/%3E%3C/circle%3E%3C/svg%3E`}
        alt="Loading..."
      />
    ) : props.icon === "google" ? (
      <span className="relative w-full">
        <GoogleIcon className="absolute top-1/2 left-0 h-5 w-5 -translate-y-1/2 transform" />
        {props.children}
      </span>
    ) : (
      props.children
    )}
  </button>
))

export default Button
