import axios from "axios"

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 30000,
})

client.interceptors.request.use((config) => {
  const access_token = localStorage.getItem("access_token")

  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`
  }

  return config
})

client.interceptors.response.use(undefined, (error) => {
  throw error.response?.data ?? error
})

export default async function sendRequest(params) {
  const response = await client({
    method: params.method,
    url: params.url ?? params.path,
    headers: params.headers,
    [params.method === "get" ? "params" : "data"]: params.payload,
  })

  return {
    data: params.transformData?.(response.data) ?? response.data,
    meta: params.extractMetaFromData?.(response.data) ?? {},
  }
}
