import React from "react"
import cn from "classnames"

export default function Heading(props) {
  return (
    <h2
      className={cn(
        "text-lg font-bold tracking-wide text-gray-900",
        props.align === "center" && "text-center",
      )}
    >
      {React.Children.map(props.children, (child) => {
        if (child.type === "mark") {
          return React.cloneElement(child, {
            className: "bg-transparent text-brand-primary",
          })
        }

        return child
      })}
    </h2>
  )
}
